body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.explodebutton {
  background-color: #fff;
  border: "1px solid black";
  padding: 10px;
  text-align: center;
  margin: 4px 2px;
  border-radius: 4px;
}
.explodebutton:hover {
  background-color: #f2f3f5;
}

.button {
  background-color: #282a35;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 4px;
}
.button:hover {
  background-color: #04aa6d;
}
.assembly {
  background-color: #717883;
  border: none;
  color: white;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 4px;
}

.signin-input {
  background: #ffffff33 0% 0% no-repeat padding-box;
  display: block;
  width: 100%;
  height: 40;
  /* height: calc(1.5em + 0.75rem + 2px); */
  padding: 0.375rem 0.75rem;
  font-size: 18 !important;
  /* font-weight: 600; */
  /* line-height: 1.5; */
  color: #ffffff;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
